<template>
  <div>
    <div class="card p-1">
      <div class="row">
        <div class="col-md-12">
          <div class="card-cradit-blue p-1">
            <label class="h6 m-0 text-white"><i class="fas fa-users" /> ออนไลน์วันนี้</label>
            <h3 class="text-white">
              0 คน
            </h3>

            <small class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหาผู้ใช้งาน
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="username"
                type="text"
                class="form-control"
                placeholder="ชื่อผู้ใช้งาน"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ค้นหาผู้ใช้งาน</label>
              <b-form-select
                v-model="usernameSelect"
                options="user"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">เวลาเริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">เวลาสิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button class="btn btn-gradient-dark">
              ค้นหาผู้ใช้งาน
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-1">
        <div class="row">
          <div class="col d-flex align-items-center">
            <h3 class="text-dark font-medium-2">
              <i class="far fa-clipboard-user" /> รายงานข้อมูลลูกค้าทั้งหมดของฉัน (0 คน)
            </h3>
          </div>
        </div>
      </div>
      <b-table
        hover
        responsive="sm"
        :items="items"
        :fields="fields"
      >
        <template #cell(id)="data">
          <router-link :to="{name: ''}">
            {{ data.item.id }}
          </router-link>
        </template>
        <template #cell(action)="">
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
            >
              <i class="fa fa-edit" />
            </b-button>
            <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BButton, BTable, BFormSelect } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BTable,
    BFormSelect,
    flatPickr,
  },
  data() {
    return {
      message: 'http://testing.power100.net/sigup?ref=123456s',
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'id', label: 'id' },
        { key: 'name', label: 'ผู้ใช้งาน' },
        { key: 'tel', label: 'เบอร์โทรศัพท์' },
        { key: 'register_at', label: 'สมัครเมื่อ' },
        { key: 'depo_first_at', label: 'ฝากครั้งแรก' },
        { key: 'depo_first', label: 'ยอดฝากแรก' },
      ],
      items: [
        {
          id: '61566',
          name: '2ชม.ที่แล้ว / John Doe (awqe4as12)',
          tel: '0612345678',
          register_at: '-',
          depo_first_at: '-',
          depo_first: '-',
        },
        {
          id: '61566',
          name: '2ชม.ที่แล้ว / John Doe (awqe4as12)',
          tel: '0612345678',
          register_at: '-',
          depo_first_at: '-',
          depo_first: '-',
          _rowVariant: 'warning',
        },
      ],
    }
  },
  methods: {
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกลิ้งค์สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
}
</script>

  <style scoped>
  .darker {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #23292E !important;
  }
  .bluer {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #2E5788 !important;
  }
  .bluesea {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #1f91fc !important;
  }
  .orange {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #EF9B55 !important;
  }
  .green {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #5cc765 !important;
  }
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
